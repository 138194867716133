import React, { useState, useEffect, useContext, useRef } from 'react'
import PageContext from '@context'
import { makeStyles } from '@material-ui/styles'
import {
  isInBrowser,
  isUX1Country,
  multilinkToUrl,
  trackFormSubmission,
} from '@helpers'
import { navigate } from 'gatsby'
import SbEditable from 'storyblok-react'
import PageContainer from '@system/page-container'
import { Box } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { H4, Text } from '@system'
import {
  getCookie,
  getEncryptedCookie,
  formCookieKey,
  saveEncryptedValuesToCookieStore,
} from '@cookies'
import {
  mktoTrialSignup,
  mktoTrialSignupStyles,
} from '../../vendor/mkto-trial-signup'
import classNames from 'classnames'
import useStringTranslation from '@hooks/use-string-translation'
import useLocalizedStrings from '@hooks/use-localized-strings'
import {
  privacyTextKey,
  checkBoxKey,
  termsKey,
} from '@variables/static-strings'

const useStyles = makeStyles((theme) => ({
  form: {
    '& .mktoForm': {
      width: '100% !important',
      display: 'flex',
      flexWrap: 'wrap',
    },
    '& .mktoFormRow': {
      width: ({ isTwoColumn }) => (isTwoColumn ? '50%' : '100%'),
      padding: '0px 10px',
      marginBottom: '20px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      '& .mktoFormCol': {
        width: '100%',
      },
      '& textarea': {
        border: '1px solid #666',
        borderRadius: '4px',
      },
      '& .mktoTextField, .mktoEmailField': {
        border: '1px solid #666',
        borderRadius: '0',
        height: '44px',
        padding: '0px 14px',
        fontSize: '20px',
        backgroundColor: '#fff',
        width: '100% !important',
        '&:focus': {
          outline: 'none',
          boxShadow: '0px 0px 0px 1px #1858A8',
        },
      },
    },
    '& .mktoFormRow.fullWidthRow': { width: '100%' },
    '& select': {
      border: '1px solid #666',
      borderRadius: '0',
      height: '44px',
      padding: '0px 14px',
      fontSize: '20px',
      backgroundColor: '#fff',
      width: '100% !important',
      '&:focus': {
        outline: 'none',
        boxShadow: '0px 0px 0px 1px #1858A8',
      },
      boxSshadow: 'none',
      outline: 'none',
      appearance: 'none',
      backgroundImage: `url(
        'data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'
      )`,
      backgroundRepeat: 'no-repeat, repeat',
      backgroundPosition: 'right .7em top 50%, 0 0',
      backgroundSize: '.65em auto, 100%',
      filter: 'grayscale(100%)',
    },
    '& .mktoLabel': {
      width: '100% !important',
      marginBottom: '10px',
      color: theme.palette.text.font,
      fontWeight: theme.typography.fontWeightBold,
    },
    '& .mktoHasWidth': { width: '100% !important' },
    '& .mktoOffset': { display: 'none !important' },
    '& .mktoGutter': { display: 'none !important' },
    '& .mktoAsterix': { display: 'none !important' },
    '& .mktoHidden': { display: 'none !important' },
    '& .mktoFieldWrap': {
      display: 'flex',
      flexDirection: 'column',
      float: 'none !important',
    },
    '& textarea': {
      padding: '0px 14px',
      fontSize: '20px',
      backgroundColor: '#fff',
      border: '0',
      width: '100% !important',
      height: '6.4em !important',
      '&:focus': {
        outline: 'none',
        boxShadow: '0px 0px 0px 1px #1858A8',
      },
    },
    '& .mktoButtonRow': {
      padding: '11px 10px',
      display: 'flex',
      justifyContent: ({ isTwoColumn, twoColumnSubmitButtonCentered }) =>
        twoColumnSubmitButtonCentered
          ? 'center'
          : isTwoColumn
          ? 'flex-end'
          : 'center',
      order: ({ twoColumnSubmitButtonCentered }) =>
        twoColumnSubmitButtonCentered && '1',
      width: ({ twoColumnSubmitButtonCentered }) =>
        twoColumnSubmitButtonCentered && '100%',
      alignItems: 'center',
      '& .mktoButtonWrap': {
        marginLeft: '0px !important',
        '& button': {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main,
          borderRadius: '4px',
          width: '174px',
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '0',
          fontSize: '13px',
          fontWeight: '600',
        },
      },
    },
    '& .mktoCheckboxList > input': {
      appearance: 'none',
      backgroundColor: theme.palette.common.white,
      width: '20px',
      height: '20px',
      border: `1px solid ${theme.palette.common.black}`,
      borderRadius: '2px',
      margin: '5px 0 0',
      display: 'grid',
      placeContent: 'center',
      float: 'left',
      [theme.breakpoints.down('sm')]: {
        margin: '9px 0',
      },
      '&::before': {
        content: '""',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url("data:image/svg+xml;utf8,<svg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.5 4.40741L4.68182 8L10.5 1.5' stroke='white' stroke-width='1.5' stroke-linecap='square'/></svg>")`,
        width: '18px',
        height: '18px',
        transition: '120ms transform ease-in-out',
      },
      '&:checked::before': {
        backgroundColor: theme.palette.common.black,
      },
      '&:focus': {
        outline: 'none',
        boxShadow: `0px 0px 0px 1px ${theme.palette.legacy.primary}`,
      },
    },
    '& .mktoCheckboxList > label': {
      minHeight: '40px !important',
      marginTop: '-.1em !important',
      padding: '4px 0 !important',
      marginLeft: '2.25em !important',
      lineHeight: '1.25em !important',
      [theme.breakpoints.down('sm')]: {
        padding: '8px 0 !important',
      },
      '&.optInLabel': {
        marginLeft: '2.75em !important',
      },
    },
  },
  formContainer: {
    position: 'relative',
    minHeight: '500px',
  },
  formBody: {
    opacity: ({ isFormVisible }) => (isFormVisible ? 1 : 0),
    transition: '1s opacity ease',
  },
  formSkeleton: {
    width: '100%',
    position: 'absolute',
    top: 0,
    opacity: ({ isFormVisible }) => (isFormVisible ? 0 : 1),
    backgroundColor: theme.palette.background.slite,
    padding: '0.5rem 0',
    transition: '1s opacity ease',
  },
  skeletonCenter: {
    margin: '1rem auto',
  },
  skeletonRow: {
    margin: '1.5rem 0',
  },
  skeletonLabel: {
    margin: '.75rem auto',
    width: '80%',
  },
  skeletonField: {
    margin: '0 auto',
  },
  skeletonWhite: {
    backgroundColor: theme.palette.common.white,
  },
  thankyouMessage: {
    padding: '1rem',
    textAlign: 'center',
    fontWeight: '500',
    fontSize: '1.4rem',
    color: theme.palette.text.matterhorn,
  },
  checkboxContainer: {
    width: '72%',
    padding: '0.3em',
    margin: '1rem 10px !important',
    fontSize: '11px !important',
    '& a': {
      color: theme.palette.text.matterhorn,
    },
    '& br': {
      margin: '1rem 0 !important',
    },
  },
  anchorTag: {
    color: 'color',
    display: 'inline',
    borderBottom: `1px dashed ${theme.palette.text.matterhorn}`,
    textTransform: 'none',
    textDecoration: 'none',
  },
  description: {
    color: theme.palette.text.primary,
    textAlign: 'center',
    marginBottom: '16px',
    display: 'block',
  },
  title: {
    color: theme.palette.text.primary,
    marginBottom: '16px',
    textAlign: 'center',
    display: 'block',
  },
}))

const MarketoFormEmbedModule = ({ blok, handleNavatticModule }) => {
  const [isFormLoaded, setFormLoadingState] = useState(false)
  const [isFormVisible, setFormVisibility] = useState(false)
  const formRef = useRef(null)
  const context = useContext(PageContext)
  const localizedStrings = useLocalizedStrings()
  const { isInEditor, locale } = context
  const pageDataProduct =
    !!context.children[1].props.blok &&
    context.children[1].props.blok.dataProduct
  const {
    title,
    description,
    marketoId,
    submitButton,
    privacyText,
    titleIsH1ForPage,
    isTwoColumn,
    twoColumnSubmitButtonCentered,
    selectedTrialUx,
    isNonMarketingForm,
  } = blok
  const classes = useStyles({
    isTwoColumn,
    isFormVisible,
    twoColumnSubmitButtonCentered,
  })

  useEffect(() => {
    if (isInBrowser) {
      if (!document.getElementById('mktoForms')) {
        loadScript()
      } else {
        setFormLoadingState(true)
      }
    }
  }, [])

  const PRIVACY_TEXT = useStringTranslation(privacyTextKey)
  const CHECKBOX_TEXT = useStringTranslation(checkBoxKey)
  const TERMS_TEXT = useStringTranslation(termsKey)

  useEffect(() => {
    if (isFormLoaded) {
      window.MktoForms2.loadForm(
        '//constructioncloud.autodesk.com',
        '572-JSV-775',
        blok.formId,
        (form) => {
          const formBody = document.getElementById(`mktoForm_${blok.formId}`)

          if (
            !isUX1Country(locale) &&
            !isNonMarketingForm &&
            !blok.isTrialUxFlow &&
            blok.formId !== '3091'
          ) {
            formBody.insertAdjacentHTML(
              'beforeend',
              `<div class="mktoCheckboxList ${classes.checkboxContainer}" ><input type='checkbox' id='optinDataCollection' name='optinDataCollection' /><label class="optInLabel" for='optinDataCollection'>${TERMS_TEXT}</label></Br> <input type='checkbox' id='optinMarketingCommunications'
              name='optinMarketingCommunications'/><label class="optInLabel" for='optinMarketingCommunications'> ${CHECKBOX_TEXT}</label></div>`
            )
          }

          const optinCheckbox = document.getElementById('optinDataCollection')
          const submitButton = document.querySelector(
            ".mktoButton[type='submit']"
          )

          submitButton.innerHTML =
            localizedStrings[submitButton.innerHTML.toLowerCase()] ||
            submitButton.innerHTML

          const handleChange = () => {
            const isCheckboxIconChecked = document.getElementById(
              'optinDataCollection'
            ).checked

            submitButton.disabled = !isCheckboxIconChecked
          }

          if (optinCheckbox) {
            optinCheckbox.addEventListener('click', handleChange)
          }

          const pageConversionType =
            !!context.children[1].props.blok &&
            context.children[1].props.blok.conversionType

          const firstNameInput = document.getElementById('FirstName')
          const lastNameInput = document.getElementById('LastName')
          const companyInput = document.getElementById('Company')
          const titleInput = document.getElementById('Title')
          const emailInput = document.getElementById('Email')
          const webinarSelectCheckboxes = document.querySelectorAll(
            'input[name="webinarProgramName"]'
          )
          webinarSelectCheckboxes.forEach((input) => (input.checked = true))

          const formRows = Array.from(
            formBody.getElementsByClassName('mktoFormRow')
          )

          formRows.forEach((row) => {
            if (
              row.getElementsByTagName('textarea').length ||
              row.getElementsByClassName('mktoCheckboxList').length
            ) {
              row.classList.add('fullWidthRow')
            }
            if (row.firstChild.attributes.type?.value === 'hidden') {
              row.classList.add('mktoHidden')
            }
          })

          // Load values from cookie store into form
          const savedValues = getCookie(formCookieKey)
          if (savedValues) {
            const decryptedValues = getEncryptedCookie(savedValues)
            if (firstNameInput && decryptedValues.FirstName) {
              firstNameInput.value = decryptedValues.FirstName
            }
            if (lastNameInput && decryptedValues.LastName) {
              lastNameInput.value = decryptedValues.LastName
            }
            if (companyInput && decryptedValues.Company) {
              companyInput.value = decryptedValues.Company
            }
            if (titleInput && decryptedValues.Title) {
              titleInput.value = decryptedValues.Title
            }
            if (emailInput && decryptedValues.Email) {
              emailInput.value = decryptedValues.Email
            }
          }

          if (blok.isTrialUxFlow && !isNonMarketingForm) {
            // Parse markdown links
            const parsedPrivacyText = PRIVACY_TEXT.replace(
              /\[(.*?)\]\((.*?)\)/gi,
              '<a href="$2">$1</a>'
            )

            formBody.insertAdjacentHTML(
              'beforeend',
              `<div id="impliedConsent" style="visibility: hidden;">
                <span>${parsedPrivacyText}</span>
                  </div>`
            )

            mktoTrialSignup(form, locale, selectedTrialUx)
          } else {
            // handle submit button disabling
            if (submitButton && !isUX1Country(locale)) {
              submitButton.disabled = true
            }

            form.onSubmit(() => {
              // prettier-ignore
              const formId = blok.formId
              const dataProduct =
                blok.dataProduct || pageDataProduct || 'generic'

              const formValues = Object.assign(
                {},
                !!firstNameInput && { FirstName: firstNameInput.value },
                !!lastNameInput && { LastName: lastNameInput.value },
                !!companyInput && { Company: companyInput.value },
                !!titleInput && { Title: titleInput.value },
                !!emailInput && { Email: emailInput.value }
              )

              saveEncryptedValuesToCookieStore(formValues, savedValues)
              window?.qualified('saveFormData', formValues)

              if (handleNavatticModule) {
                trackFormSubmission(formId, dataProduct).then(() => {
                  handleNavatticModule()
                })

                return false
              }

              const thankyouMessage = document.createElement('div')
              const url = blok.typUrl && multilinkToUrl(blok.typUrl, isInEditor)
              const hasTypUrl = !!url

              thankyouMessage.innerHTML = `<div class=${classes.thankyouMessage}>${blok.successMessage}</div>`

              const handleTyp = () => {
                if (hasTypUrl) {
                  navigate(url, {
                    state: {
                      conversionType: pageConversionType,
                    },
                  })
                  return
                }

                if (!!blok.successMessage) {
                  formBody.parentNode.replaceChild(thankyouMessage, formBody)
                }
              }

              trackFormSubmission(formId, dataProduct).then(handleTyp())

              return false
            })

            form.onSuccess(() => {
              return false
            })
          }
        }
      )
    }
  }, [isFormLoaded, blok.formId])

  useEffect(() => {
    const checkFormVisibility = setInterval(() => {
      if (!isFormVisible) {
        const formContent = formRef.current?.querySelector('.mktoFormRow')
        setFormVisibility(!!formContent)
      }
    }, 250)
    return () => clearInterval(checkFormVisibility)
  }, [formRef, isFormVisible])

  const loadScript = () => {
    const s = document.createElement('script')
    s.id = 'mktoForms'
    s.type = 'text/javascript'
    s.async = true
    s.src = '//constructioncloud.autodesk.com/js/forms2/js/forms2.min.js'
    s.onreadystatechange = function () {
      if (this.readyState === 'complete' || this.readyState === 'loaded') {
        setFormLoadingState(true)
      }
    }
    s.onload = () => setFormLoadingState(true)
    document.getElementsByTagName('head')[0].appendChild(s)
  }

  if (blok.isTrialUxFlow) {
    const mktoTrialSignupClasses = mktoTrialSignupStyles()
    return (
      <div className={mktoTrialSignupClasses.form}>
        <form id={`mktoForm_${blok.formId}`} />
      </div>
    )
  }

  const LoadingSkeleton = () => (
    <Box className={classes.formSkeleton}>
      <Skeleton
        variant="text"
        width="50%"
        height={20}
        className={classes.skeletonCenter}
      />
      {Array.from('1234').map((rowNum) => (
        <Box key={`skeletonRow${rowNum}`} className={classes.skeletonRow}>
          <Box className={classes.skeletonLabel}>
            <Skeleton variant="text" width="30%" height={15} />
          </Box>
          <Skeleton
            variant="rect"
            className={classNames(classes.skeletonField, classes.skeletonWhite)}
            width="80%"
            height={45}
          />
        </Box>
      ))}
      <Skeleton
        variant="rect"
        width="45%"
        height={40}
        className={classes.skeletonCenter}
      />
    </Box>
  )

  return (
    <SbEditable content={blok}>
      <PageContainer backgroundColor="transparent">
        <Box className={classes.form}>
          {!!title && (
            <H4
              component={titleIsH1ForPage ? 'h1' : 'h4'}
              display="block"
              className={classes.title}
            >
              {title}
            </H4>
          )}
          {!!description && (
            <Text display="block" className={classes.description}>
              {description}
            </Text>
          )}
          <Box className={classes.formContainer}>
            <form
              id={`mktoForm_${blok.formId}`}
              ref={formRef}
              className={classes.formBody}
            />
            {!isFormVisible && <LoadingSkeleton />}
          </Box>
        </Box>
      </PageContainer>
    </SbEditable>
  )
}

export default MarketoFormEmbedModule
