import { makeStyles } from '@material-ui/styles'
import { setCookie } from '@cookies'
export function mktoTrialSignup(form, location, selectedTrialUx) {
  document.querySelector('#impliedConsent').style.visibility = 'hidden'
  var selectedTrialUxValues = ['amer', 'eu', 'takeoff']
  if (selectedTrialUxValues.includes(selectedTrialUx)) {
    setCookie('selectedTrialUx', selectedTrialUx, 30)
  }
  var locale = location || 'us'
  var UX1Countries = ['default', 'in', 'jp', 'mx', 'us']

  if (UX1Countries.includes(locale)) {
    document.querySelector('#impliedConsent').style.visibility = 'visible'
  }

  var namedCountry = ''

  if (locale === 'default' || locale === 'us') {
    namedCountry = 'United States'
  } else if (locale === 'mx') {
    namedCountry = 'Mexico'
  } else if (locale === 'in') {
    namedCountry = 'India'
  } else if (locale === 'jp') {
    namedCountry = 'Japan'
  } else if (locale === 'ca') {
    namedCountry = 'Canada'
  } else if (locale === 'gb') {
    namedCountry = 'United Kingdom'
  }

  var inputCountry = document.querySelector("input[name='Country']")
  inputCountry.value = namedCountry

  var inputCountryOnChangeEvent = new Event('change')
  inputCountry.dispatchEvent(inputCountryOnChangeEvent)

  var inputTos = document.querySelector("input[name='optinDataCollection']")
  if (inputTos) {
    var buttonSubmit = document.querySelector("button[type='submit']")
    buttonSubmit.disabled = true

    inputTos.addEventListener('change', (event) => {
      if (event.target.checked) {
        buttonSubmit.disabled = false
      } else {
        buttonSubmit.disabled = true
      }
    })
  }

  function waitForError() {
    var element, input, mktoErrorMsg
    var vals = form.vals()
    element = document.querySelector('.mktoErrorMsg')
    if (element) {
      mktoErrorMsg = element.textContent || element.innerText
      input = document.querySelector('input.mktoInvalid, .mktoInvalid input')
      analytics.context.changeContext(
        {
          event: { eventType: 'form_submit,form_error' },
          form: {
            platformSystem: 'marketo',
            offerActivityID: JSON.stringify(vals.formid),
            formErrors: (input && input.name) || '',
          },
        },
        'linktracking'
      )
    }
  }

  form.onValidate(function () {
    setTimeout(waitForError, 500)
  })

  form.onSubmit(function () {
    var vals = form.vals()
    // window.alert('Submitted values: ' + JSON.stringify(vals.formid))
    analytics.context.changeContext(
      {
        event: { eventType: 'form_submit,form_success' },
        form: {
          platformSystem: 'marketo',
          offerActivityID: JSON.stringify(vals.formid),
        },
      },
      'linktracking'
    )
  })

  form.onSuccess(function (values, _followUpUrl) {
    var cookieselectedTrialUx = document.cookie
      .match('(^|;)\\s*selectedTrialUx\\s*=\\s*([^;]+)')
      ?.pop()
    var form = document.createElement('form')
    const cookieselectedTrialUxURL = () => {
      switch (cookieselectedTrialUx) {
        case 'eu':
          return 'https://acc-trials-eu.autodesk.com/trial-signup/i/lookup/index.php'
        case 'takeoff':
          return 'https://acc-trials-takeoff.autodesk.com/trial-signup/i/lookup/index.php'
        default:
          return 'https://acc-trials.autodesk.com/trial-signup/i/lookup/index.php'
      }
    }
    form.setAttribute('action', cookieselectedTrialUxURL())
    form.setAttribute('method', 'POST')
    form.setAttribute('id', 'dataHiddenForm')

    var input1 = document.createElement('input')
    input1.type = 'hidden'
    input1.name = 'email'
    input1.value = values.Email
    form.appendChild(input1)

    var input2 = document.createElement('input')
    input2.type = 'hidden'
    input2.name = 'company'
    input2.value = values.Company
    form.appendChild(input2)

    var input3 = document.createElement('input')
    input3.type = 'hidden'
    input3.name = 'phone'
    input3.value = values.Phone
    form.appendChild(input3)

    document.body.appendChild(form)

    document.getElementById('dataHiddenForm').submit()

    return false
  })
}

export const mktoTrialSignupStyles = makeStyles((theme) => ({
  form: {
    '& .mktoForm': {
      color: 'inherit !important',
      fontFamily: 'inherit !important',
      width: '100% !important',
      '& input': {
        fontSize: 'inherit !important',
      },
    },
    '& a': {
      borderBottom: `1px dashed ${theme.palette.text.matterhorn}`,
      color: theme.palette.text.matterhorn,
      display: 'inline',
      padding: '0 !important',
      textDecoration: 'none',
      textTransform: 'none',
    },
    '& .mktoFormRow': {
      '&:nth-of-type(2)': {
        display: 'none',
      },
      '& .mktoFormCol': {
        marginBottom: '16px !important',
        minHeight: 'unset !important',
        width: '100%',
      },
    },
    '& .mktoFormRow.fullWidthRow': { width: '100%' },
    '& .mktoLabel': {
      color: theme.palette.text.font,
      fontWeight: theme.typography.fontWeightBold,
      marginBottom: '10px',
      width: '100% !important',
    },
    '& .mktoOffset': { display: 'none !important' },
    '& .mktoGutter': { display: 'none !important' },
    '& .mktoAsterix': { display: 'none !important' },
    '& #LbloptinMarketingCommunications': { display: 'none' },
    '& #LbloptinDataCollection': { display: 'none' },
    '& .mktoFieldWrap': {
      display: 'flex',
      flexDirection: 'column',
      float: 'none !important',
    },
    '& .mktoTextField': {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '4px',
      height: '38px !important',
      padding: '0px 14px !important',
      width: '100% !important',
      '&:focus': {
        backgroundColor: 'none',
        boxShadow: `0px 0px 0px 1px ${theme.palette.primary.main}`,
        outline: 'none',
      },
    },
    '& .mktoCheckboxList': {
      fontSize: '13px',
      padding: '0 !important',
      width: '100% !important',
      '& input': {
        marginTop: '2px',
      },
      '& label': {
        marginBottom: '0 !important',
      },
    },
    '& .mktoButtonRow': {
      display: 'block',
      '& .mktoButtonWrap': {
        display: 'block',
        margin: '4px 0 16px 0 !important',
        '& button': {
          backgroundColor: `${theme.palette.primary.main} !important`,
          backgroundImage: 'none !important',
          border: 'none !important',
          borderRadius: '4px',
          color: `theme.palette.primary.contrastText !imporant`,
          fontSize: '15px !important',
          fontWeight: '700',
          letterSpacing: '-0.18px',
          minHeight: '45px',
          minWidth: '150px',
          padding: '8px 12px',
          textTransform: 'uppercase',
          width: '100%',
        },
      },
    },
  },
}))
